.centerScreen {
  margin:auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loginArea {
  -webkit-box-shadow: 1px 1px 10px 4px rgba(0,0,0,0.5);
  background-color: white;
  box-shadow: 1px 1px 10px 4px rgba(0,0,0,0.5);
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
}

.loginAreaHeader {
    padding: 1rem;
}

.loginAreaForms {
  padding-bottom: 3rem;
  padding-top: 1rem;
}