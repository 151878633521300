// Importar variáveis
@import "variables";

// Importar componentes
@import "components/header";
@import "components/drop_zone";


.clickable {
	cursor: pointer;
	user-select: none;
}

.homeText {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}