section.dropzone{
    position: relative;
    width: calc(100vw - 10vw);
    max-width: calc(100vw - 12vw);
    height: calc(100vh - 20vw);
    max-height: calc(100vh - 12vw);
    border-radius: 10px;
    box-sizing: border-box;
    margin: 5.5vw;
    background-color: $primary_color;
    transition: 0.2s all;
    overflow: hidden;
    color:$contrast_color;
    
    &:hover{
        background-color: $light_blue;
    }

    &.hover{
        background-color: $secondary_color;
    }
    
    div{
        position: relative;
        cursor: pointer;
        width: 100%;
        height: 100%;
        text-align: center;

        p{
            display: block;
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    button{
        position: absolute;
        bottom: 20px;
        right: 20px;
        display: block;
    }

    input, div{
        outline:none;
    }
}